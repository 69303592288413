import TopContent from './TopContent'

const ContentAmbiental = () => {
  return (
    <section className="section-content">
      <TopContent />
      <article className="article">
        <h2 className="article_title">ECO DESIGN</h2>
        <p className="article_text">
          Nossos produtos são responsáveis pela maior parte de nosso impacto.
          Para reduzir isso, estamos desenvolvendo uma abordagem de ecodesign.
          <br />
          <strong>
            100% dos nossos produtos são o resultado de uma estratégia de
            ecodesign
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">
          ECOLOGIA INDUSTRIAL E LOCAL EM NOSSOS FORNECEDORES
        </h2>
        <p className="article_text">
          Estamos melhorando o desempenho ambiental das fábricas de nossos
          fornecedores, ajudando-os a reduzir continuamente seus impactos
          ambientais de acordo com seus riscos.
          <br />
          <strong>
            90% dos locais de nossos fornecedores são classNameificados como A,
            B ou C, de acordo com nossa grade de auditoria
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">ECO-MOBILIDADE DE EQUIPES</h2>
        <p className="article_text">
          Compartilhamos as melhores práticas de eco mobilidade em diferentes
          países e cidades, a fim de minimizar o impacto ambiental das viagens
          de nossos colegas de equipe.
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">REPARAR</h2>
        <p className="article_text">
          Estamos aumentando o número de produtos reparáveis e oferecendo
          serviços de reparo personalizados.
          <br />
          <strong>
            30% dos produtos em nossas linhas são classNameificados como
            reparáveis
          </strong>
          <br />
          <strong>
            100% dos nossos produtos reparáveis são reparados pelas nossas
            oficinas ou pelos nossos clientes
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">SEGUNDA VIDA</h2>
        <p className="article_text">
          Durante todo o ano, oferecemos produtos em segunda mão e garantimos
          todos os itens revendidos.
          <br />
          <strong>
            100% dos nossos países têm uma gama de produtos esportivos usados
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">ALUGUEL / ASSINATURA</h2>
        <p className="article_text">
          Estamos desenvolvendo modelos de aluguel e assinatura para atender às
          expectativas de novos padrões de consumo.
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">COMUNICAÇÃO SOBRE NOSSOS PRODUTOS</h2>
        <p className="article_text">
          Exibimos o desempenho ambiental dos produtos DECATHLON de forma clara,
          a fim de promover o consumo responsável.
          <br />
          <strong>
            100% dos produtos DECATHLON terão rotulagem ambiental até 2026
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">AÇÕES DE SENSIBILIZAÇÃO</h2>
        <p className="article_text">
          Para o benefício de nossos colegas de equipe e clientes, organizamos
          eventos destinados a aumentar a conscientização sobre a necessidade de
          preservar nosso ambiente:
        </p>
        <p className="article_text">
          (caminhadas ecológicas, dia mundial da limpeza, etc.).
          <br />
          <strong>Em média, há um evento por ano e por local</strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">PLÁSTICOS</h2>
        <p className="article_text">
          Estamos comprometidos em eliminar os plásticos descartáveis em nossa
          cadeia de valor.
          <br />
          <strong>
            0 plásticos de uso único em nossas embalagens (excluindo nutrição,
            produtos químicos e cosméticos, ou quaisquer produtos que requeiram
            proteção para fins técnicos, ou produtos sujeitos a restrições
            legais)
          </strong>
        </p>
        <p className="article_text">
          Estamos inscritos na iniciativa multipartite “Plastik Leak”, projetada
          para identificar fontes de vazamentos de microplásticos e plásticos em
          nossa cadeia de valor.
          <br />
          <strong>
            Elaboração de diretrizes metodológicas e indicadores de avaliação
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">EXCESSO INVENTÁRIO</h2>
        <p className="article_text">
          Gerenciando melhor nossas previsões de produção, somos capazes de
          reduzir os estoques excedentes de componentes e produtos acabados em
          nossa cadeia de suprimentos. Além disso, estamos procurando reutilizar
          esses estoques excedentes residuais, vendendo-os, doando-os ou
          reciclando-os.
          <br />
          <strong>
            0 componentes ou produtos acabados incinerados ou enviados para
            aterro em 2026
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">RECICLANDO</h2>
        <p className="article_text">
          Estamos trabalhando para estabelecer instalações de reciclagem para
          nossos vários tipos de produtos e apoiamos projetos de P&D projetados
          para permitir a reciclagem de nossas matérias-primas.
          <br />
          <strong>
            80% dos produtos vendidos na França possuem uma unidade de
            reciclagem
          </strong>
        </p>
        <p className="article_text">
          <strong>
            Mapeamento de instalações de reciclagem existentes e potenciais em
            nossos outros países de distribuição
          </strong>{' '}
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">DESPERDÍCIO</h2>
        <p className="article_text">
          Operamos a reciclagem e recuperação de resíduos em todos os nossos
          locais: lojas, armazéns, etc.
          <br />
          <strong>100% dos resíduos recuperados</strong>
        </p>
      </article>
    </section>
  )
}

export default ContentAmbiental
