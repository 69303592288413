const TopContent = () => {
  return (
    <>
      <article className="article">
        <h2 className="article_title">MUDANÇA CLIMÁTICA</h2>
        <p className="article_text">
          Nossa meta é ajudar o mundo a tornar-se neutro em carbono até 2050.
          Nossa contribuição até 2030 é reduzir nossas próprias emissões e ir
          mais longe na redução das emissões de outros, capturando carbono para
          compensar nossas emissões residuais em todos os escopos.
          <br />
          <strong>
            Redução de 40% em nossa intensidade de carbono entre 2016 e 2026
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">EM NOSSAS LOJAS E ARMAZÉNS</h2>
        <p className="article_text">
          Estamos reduzindo as emissões totais de CO2e para os escopos 1 e 2
          (consumo de energia e vazamentos de ar condicionado).
          <br />
          <strong>75% de redução nos escopos 1 e 2</strong>
        </p>
        <p className="article_text">
          Temos o compromisso de consumir eletricidade renovável em todas as
          nossas unidades (compra e produção direta).
          <br />
          <strong>100% de eletricidade renovável até 2026</strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">COM NOSSOS FORNECEDORES</h2>
        <p className="article_text">
          Trabalhamos com nossos principais fornecedores para garantir que sejam
          autônomos em termos de monitoramento de suas emissões de carbono.
          <br />
          <strong>
            90% dos nossos volumes de compra são produtos de fornecedores que
            gerenciam de forma autônoma suas emissões de CO2e
          </strong>
        </p>
        <p className="article_text">
          Estimulamos nossos principais fornecedores a usarem bases científicas
          para definir suas próprias trajetórias de redução de emissões de CO2e
          até o final de 2024.
          <br />
          <strong>
            90% dos nossos volumes de compra são produtos de fornecedores que
            definiram seus objetivos de CO2e de acordo com os critérios SBTi{' '}
          </strong>
        </p>
        <p className="article_text">
          Estamos incentivando nossos principais fornecedores a consumir apenas
          eletricidade renovável até 2026.
          <br />
          <strong>
            90% dos nossos volumes de compra são produtos de fornecedores que
            usam apenas eletricidade renovável
          </strong>
        </p>
        <p className="article_text">
          Apoiamos nossos fornecedores e os ajudamos a traçar planos de ação de
          eficiência energética.
          <br />
          <strong>
            90% dos nossos volumes de compra são produtos de fornecedores que
            possuem uma estratégia de eficiência energética
          </strong>
        </p>
        <p className="article_text">
          Até 2025, teremos interrompido o uso de carbono local em nossos
          fornecedores de nível 1, bem como renovadas quaisquer instalações que
          requeiram combustão de carbono em nossos fornecedores de nível 2.
          <br />
          <strong>
            Consumo 0 de carbono em nossos fornecedores de nível 1
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">TRANSPORTE</h2>
        <p className="article_text">
          Estamos reduzindo o impacto do transporte internacional em nossos
          produtos, limitando o uso de frete aéreo e viagens à estrita
          necessidade.
          <br />
          <strong>
            &lt;1% dos nossos produtos são transportados por via aérea
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">BIODIVERSIDADE</h2>
        <p className="article_text">
          Estamos estimulando medidas para enfrentar a questão da biodiversidade
          em nossa cadeia de valor, utilizando diversos métodos de referência, e
          trabalhando em diferentes iniciativas colaborativas para definir as
          melhores estratégias e ações prioritárias.
        </p>
        <p className="article_text">
          Nossas novas lojas na França têm um impacto local positivo sobre a
          biodiversidade.
          <br />
          <strong>
            100% de nossas novas lojas francesas da Decathlon possuem o
            credenciamento Signature Biodiversité
          </strong>
        </p>
        <p className="article_text">
          Nosso portfólio de propriedades na França segue uma estratégia de
          melhoria contínua para a biodiversidade.
          <br />
          <strong>
            10% do nosso portfólio de propriedades na França incorpora a
            iniciativa Nature First
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">
          FABRICAÇÃO RESPONSÁVEL DE MATERIAIS E PROCESSOS
        </h2>
        <p className="article_text">
          Estamos reduzindo o impacto ambiental dos produtos usando
          matérias-primas de suprimentos de origem responsável.
          <br />
          <strong>
            100% do nosso algodão é de fontes mais sustentáveis no final de 2020
            (reciclado, BCI, algodão orgânico)
          </strong>
        </p>
        <p className="article_text">
          <strong>
            100% de poliéster de fontes mais sustentáveis até o final de 2022
            (reciclado, tingido em solução)
          </strong>
        </p>
        <p className="article_text">
          <strong>
            100% dos nossos materiais usados para embalagens de fontes mais
            sustentáveis e certificadas *
          </strong>{' '}
          <br />
          <strong>* Celulose certificada FSC ou PEFC.</strong>
        </p>
        <p className="article_text">
          <strong>Outros materiais:</strong> <br />
          <strong>
            Identificamos os principais riscos humanos e ambientais dos
            materiais utilizados em nossa cadeia de suprimentos a fim de
            estruturar nossos compromissos
          </strong>
        </p>
      </article>
    </>
  )
}

export default TopContent
